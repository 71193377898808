<template>
  <div>
    <Header ref="header" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>消息中心</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="container">
                <div class="container-button">
                  <div :class="activeName == 0 ? 'onSelect' : ''" class="container-button-txt" style="border-right: none;" @click="onTab(0)">
                    未读消息 {{$store.state.countMsg.unRead ? `(${$store.state.countMsg.unRead})` : ''}}
                    <!-- <span v-if="this.CountMsg != 0">({{ this.CountMsg }})</span> -->
                  </div>
                  <div :class="activeName == 1 ? 'onSelect' : ''" class="container-button-txt" @click="onTab(1)">
                    已读消息 {{$store.state.countMsg.read ? `(${$store.state.countMsg.read})` : ''}}
                  </div>
                </div>
                <div v-if="tableData.length > 0" v-loading="loading">
                  <div class="content" v-for="(item, index) in tableData" :key="index">
                    <div class="content-left">
                      <div class="content-left-title">{{ item.msgTitle }}</div>
                      <div class="content-left-txt">
                        {{ item.abstractMsg }}
                        <span v-if="item.msgType != 1 && item.msgType != 2" style="margin-left: 5px;color: rgb(0,153,204);cursor: pointer" @click="onSkip(item)">请前往查看></span>
                      </div>
                      <div class="content-left-date">{{ item.createTime }}</div>
                    </div>
                    <div class="content-right">
                      <el-button @click="onDetailed(item.id)">查看详情</el-button>
                      <el-button @click="onDelete(item.id)" :key="item.id">删除</el-button>
                    </div>
                  </div>
                </div>
                <div v-else class="noData">
                  暂无数据
                </div>
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                  popper-class="popperPage">
                </el-pagination>
              </div>
            </div>
          </div>
        </el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import { countQueryPage, deleteMsg, markRead } from "@/api/api"
export default {
  components: { Header, Footer },
  data () {
      return{
        activeName: 0,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        // CountMsg: 0,
        loading: false,
        tableData: [],
        msgTypes: [
            { label: '商品下架', value: 1 },
            { label: '商品上架', value: 2 },
            { label: '订单审核', value: 3, name: 'MyOrder' },
            { label: '订单待审核通知', value: 103, name: 'MyOrder' },
            { label: '发货通知', value: 4, name: 'OrderDetails', router: (remark) => {
                if(!remark){
                    this.$message.warning('参数异常！')
                    return
                }
                let r = remark.split('_')
                let obj = {
                    orderNo: r[0],
                    invoiceId: r[1],
                    id: r[2]
                }
                this.$router.push({
                    name: "OrderDetails",
                    query: { item: JSON.stringify(obj) },
                });
            }},
            { label: '售后通知', value: 5, name: 'SalesRecord' },
            { label: '账单通知', value: 6, name: 'MyBill' },
            { label: '品牌通知', value: 7 },
            { label: '外部企业信息', value: 8 },
            { label: '预充值消息', value: 9, name: 'MyTopUp' },
            { label: '支付凭证通知', value: 11, name: 'MyBill', handle: (remark) => {
                return remark ? remark.split('_')[1] : ''
            }},
        ]
      }
  },
  created() {
    if (sessionStorage.getItem('news-activeName')) {
      let activeName = sessionStorage.getItem('news-activeName')
      this.activeName = parseInt(activeName)
      // sessionStorage.removeItem('news-activeName')
    }
    this.getList()
  },
  methods:{
    // 跳转
    onSkip(data) {
        let obj = this.msgTypes.find(item => item.value == data.msgType)
        if(obj.name){
            // 标记已读
            markRead({id: data.id})
            if(obj.router){
                obj.router(data.remark)
            } else if (data.remark) {
                let searchId = obj.handle ? obj.handle(data.remark) : data.remark
                this.$router.push({
                    name: obj.name,
                    params:{
                        searchId
                    }
                })
            } else {
                this.$router.push({ name: obj.name})
            }
        }
    },
    // 切换tab
    onTab(index) {
      this.activeName = index
      this.currentPage = 1
      this.getList()
    },
    // 列表数据
    getList() {
      let data = {
        myPage: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        },
        readFlag: this.activeName
      }
      this.loading = true
      countQueryPage(data).then(res => {
          this.loading = false
          if (res.code == '00000') {
              this.tableData = res.data.records
              this.total = res.data.total
              this.$refs.header.onCountMsg()
            //   if (this.activeName == 0) {
            //     this.CountMsg = res.data.total
            //   }
          } else {
              this.$message({message: res.desc, type: 'error'})
          }
      })
    },
    // 每页条数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    // 详情
    onDetailed(id) {
      sessionStorage.setItem('news-activeName', this.activeName)
      this.$router.push({
        name: "newsDetailed",
        query: {
          id: id
        }
      });
    },
    // 删除
    onDelete(id) {
      this.$confirm("确认删除这条信息吗？", {
        confirmButtonText: "确认删除",
        cancelButtonText: "暂不删除",
        type: "warning",
      })
        .then(() => {
          deleteMsg(id).then(res => {
            if (res.code == "00000") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList()
              this.$refs.header.onCountMsg()
            } else {
              this.$message({message: '删除失败', type: 'error'})
            }
          });
        })
        .catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.noData {
  text-align: center;
  color: #343333;
}
.container {
  width: 100%;
  min-width: 800px;
  border: 1px solid #898686;
  padding: 20px;
  border-radius: 5px;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bdbcbc;
  margin-bottom: 10px;
}
.content-left-title {
  font-size: 17px;
  color: #333131;
}
.content-left-txt {
  font-size: 14px;
  color: #585656;
  margin: 10px 0;
}
.content-left-date {
  font-size: 15px;
  color: #525050;
  margin-bottom: 5px;
}
.content-left {
  flex: 1;
}
.content-right {
  width: 185px;
  margin-left: 25px;
}
.container-button {
  display: block;
  display: flex;
  margin-bottom: 20px;
}
.container-button-txt {
  padding: 10px 15px;
  border: 1px solid #898686;
  cursor: pointer;
}
.onSelect {
  background: rgb(153,0,0);
  color: #fff;
  }
.settings_box{
  width: 100%;
  margin: 30px 0;
}
.el-breadcrumb{
  font-size: 18px;
  padding-bottom: 20px;
}
</style>